import React, { ReactNode } from "react";

import CommonJobCardContext, {
  UserStudentExploreCardContextProps,
} from "./CommonJobCardContext";

const CommonJobCardProvider = ({
  children,
  ...props
}: UserStudentExploreCardContextProps & { children: ReactNode }) => {
  return (
    <CommonJobCardContext.Provider value={{ ...props }}>
      {children}
    </CommonJobCardContext.Provider>
  );
};

export default CommonJobCardProvider;
