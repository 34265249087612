"use client";

import { createContext } from "react";
import { CompatibilityScore, Job } from "@koble/graphql";

export interface UserStudentExploreCardContextProps {
  job: Job;
  profileImagePlaceholder: string;
  compatibilityScore?: CompatibilityScore;
}

const CommonJobCardContext = createContext<UserStudentExploreCardContextProps>({
  job: {} as Job,
  profileImagePlaceholder: "",
  compatibilityScore: {} as CompatibilityScore,
});

export default CommonJobCardContext;
