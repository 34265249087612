const colors = [
  "magenta",
  "volcano",
  "orange",
  "green",
  "blue",
  "cyan",
  "geekblue",
  "purple",
];

const stringToColor = (str: string) => {
  const sum = str.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  return colors[sum % colors.length];
};

export default stringToColor;
