import React, { ReactNode } from "react";
import { CompatibilityScore, Job } from "@koble/graphql";
import { Badge, Card, Divider, GlobalToken, theme } from "antd";

import CommonAdaptiveLink from "../../CommonAdaptiveLink";

import CommonJobCardBody from "./CommonJobCardBody";
import CommonJobCardFooter from "./CommonJobCardFooter";
import CommonJobCardHeader from "./CommonJobCardHeader";
import CommonJobCardProvider from "./CommonJobCardProvider";
import CommonJobCardTags from "./CommonJobCardTags";

type ExtendedGlobalToken = GlobalToken & {
  boxShadowCard?: string;
};

const CommonJobCard = ({
  job,
  profileImagePlaceholder,
  ribbonText,
  extraContent,
  ssrUrl,
  clientUrl,
  compatibilityScore,
  selectedJob,
  hoverable,
  onClick,
}: {
  job: Job;
  profileImagePlaceholder: string;
  ribbonText?: string;
  extraContent?: ReactNode;
  ssrUrl: string;
  clientUrl?: string;
  compatibilityScore?: CompatibilityScore;
  selectedJob?: boolean;
  hoverable?: boolean;
  onClick?: (j: Job) => void;
}) => {
  const token = theme.useToken().token as ExtendedGlobalToken;

  const Ribbon = ({ children }: { children: ReactNode }) => {
    if (ribbonText) {
      return (
        <Badge.Ribbon text={ribbonText} color="purple" style={{ zIndex: 1 }}>
          {children}
        </Badge.Ribbon>
      );
    }
    return children;
  };

  return (
    <CommonJobCardProvider
      job={job}
      profileImagePlaceholder={profileImagePlaceholder}
      compatibilityScore={compatibilityScore}
    >
      <Ribbon>
        <Card
          hoverable={hoverable}
          style={{
            border: selectedJob ? "2px solid #0984e3" : undefined,
            boxShadow: selectedJob ? token.boxShadowCard : undefined,
          }}
          styles={{ body: { padding: 0 } }}
        >
          <CommonAdaptiveLink
            ssrUrl={ssrUrl}
            clientUrl={clientUrl}
            scroll={false}
          >
            <div
              role="button"
              tabIndex={-1}
              onClick={() => onClick && onClick(job)}
              style={{
                padding: 15,
                ...(extraContent && { paddingBottom: 8 }),
              }}
            >
              <CommonJobCardTags />
              <CommonJobCardHeader />
              <CommonJobCardBody />
              <Divider style={{ marginBottom: 7, marginTop: 10 }} />
              <CommonJobCardFooter />
            </div>
          </CommonAdaptiveLink>
          {extraContent && (
            <div style={{ padding: 15, paddingTop: 0, cursor: "default" }}>
              {extraContent}
            </div>
          )}
        </Card>
      </Ribbon>
    </CommonJobCardProvider>
  );
};

export default CommonJobCard;
