import React from "react";
import stringToColor from "@koble/utils/src/stringToColor";
import { Space, Tag, Tooltip, Typography } from "antd";

import commonColors from "../../commonColors";
import { CommonSymbology } from "../../commonSymbology";

import useCommonJobCard from "./useCommonJobCard";

const CommonJobCardTags = () => {
  const { job, compatibilityScore } = useCommonJobCard();

  return (
    <div style={{ marginBottom: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {job.area && (
          <Tooltip title={job.area.spanishName}>
            <Tag
              color={stringToColor(job.area.areaId)}
              style={{
                flexShrink: 1, // Allow the area tag to shrink
                minWidth: 0, // Prevent overflow by allowing it to shrink
                overflow: "hidden", // Ensure no overflow text is visible
                textOverflow: "ellipsis", // Show ellipsis if text is too long
              }}
            >
              {job.area.spanishName.length > 30
                ? `${job.area.spanishName.slice(0, 30)}...`
                : job.area.spanishName}
            </Tag>
          </Tooltip>
        )}
        {compatibilityScore && (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip
              title={`Tienes ${compatibilityScore.score}% de compatibilidad con esta vacante`}
            >
              <Space direction="horizontal" size={4}>
                <CommonSymbology.Compatibility />
                <Typography.Text
                  style={{
                    color: commonColors.compatibility,
                    fontWeight: 600,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {compatibilityScore.score}%
                </Typography.Text>
              </Space>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonJobCardTags;
